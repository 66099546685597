
    import { Component, Vue } from 'nuxt-property-decorator';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '../editor/config/types';
    import { Prop } from 'nuxt-property-decorator';

    export const meta: ComponentMeta = {
        options: {
            userProps: {
                title: 'Свойства',
                type: AvailableTypes.array,
                item: {
                    code: {
                        type: AvailableTypes.string
                    },
                    value: {
                        type: AvailableTypes.string
                    }
                }
            },
            template: {
                description: 'Свойства доступны в объекте data',
                title: 'Шаблон',
                language: 'html',
                type: AvailableTypes.code
            },
            styles: {
                title: 'CSS',
                language: 'css',
                type: AvailableTypes.code
            },
            images: {
                title: 'Картинки',
                type: AvailableTypes.arrayOfType,
                item: {
                    type: AvailableTypes.image
                }
            }
        },
        group: AvailableGroups.Другое,
        title: 'Свой компонент',
        style: {
            'padding-bottom': '2rem'
        }
    };

    @Component({
        components: {}
    })
    export default class CustomComponent extends Vue {
        @Prop() template;
        @Prop() styles;
        @Prop({
            default: () => []
        })
        userProps;

        data = {};
        currentRender = null;

        created() {
            if (this.userProps) {
                this.userProps.forEach(prop => {
                    this.data[prop.code] = this.parseJson(prop.value);
                });
            }
        }

        parseJson(value) {
            try {
                return JSON.parse(value);
            } catch (e) {
                return value;
            }
        }
    }
